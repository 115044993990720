<template>
  <v-app>
    <v-main class="page">
      <v-container>
        <div class="component">
          <v-icon style="font-size: 200px">mdi-web</v-icon>
        </div>
        <div class="text-center">
          <h2>Pagina Não Encontrada</h2>
          <v-btn @click="$router.push('/')" text color="primary"
            ><h3>Voltar para o inicio</h3>
          </v-btn>
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  methods: {},
};
</script>

<style lang="sass" scoped>
.component
  margin-top: 5%
  display: flex
  align-items: center
  justify-content: center
  align-content: center
</style>